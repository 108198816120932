
import { PropType, defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import Testimonial from "./Testimonial.vue";
import { ITestimonial } from "@/types";

export default defineComponent({
  components: {
    Testimonial,
    Carousel,
    Slide,
    Pagination,
  },
  props: {
    testimonials: {
      required: true,
      type: Array as PropType<ITestimonial[]>,
    },
  },
});
