
import { defineComponent } from "vue";
import { Footer, Navigation } from "@/components";

export default defineComponent({
  name: "App",
  components: {
    Footer,
    Navigation,
  },
});
