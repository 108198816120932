
import { defineComponent } from "vue";
import SkillsBlock from "./SkillsBlock.vue";

export default defineComponent({
  name: "SkillsOverview",
  components: {
    SkillsBlock,
  },
  setup() {
    return {};
  },
});
