
import { PropType, defineComponent } from "vue";
import { Carousel, CoverImage, Quote, Testimonial } from "@/components";
import { useDB, useProject } from "@/composables";
import "vue3-carousel/dist/carousel.css";
import { IProject, ITestimonial } from "@/types";

export default defineComponent({
  name: "Seerene",
  components: {
    CoverImage,
    Carousel,
    Testimonial,
    Quote,
  },
  props: {
    project: {
      type: Object as PropType<IProject>,
      required: true,
    },
    testimonials: {
      type: Array as PropType<ITestimonial[]>,
      required: true,
    },
  },
  setup(props) {
    const otherCaseStudies = ["opera-mobile"];
    const { projects } = useDB();
    const { getImagesPaths } = useProject();
    return {
      projects: projects.filter((p) => otherCaseStudies.includes(p.slug)),
      imagesPaths: getImagesPaths(props.project, 3),
    };
  },
});
