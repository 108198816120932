import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "header-intro theme-bg-primary text-white py-5" }
const _hoisted_2 = { class: "container position-relative" }
const _hoisted_3 = { class: "page-heading mb-3" }
const _hoisted_4 = { class: "page-heading-tagline" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
      _createVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "tagline")
      ]),
      _renderSlot(_ctx.$slots, "logo")
    ])
  ]))
}