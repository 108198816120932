
import { PropType, defineComponent } from "vue";

export default defineComponent({
  name: "SkillsBlock",
  props: {
    groupName: {
      required: true,
      type: String,
    },
    icon: {
      required: true,
      type: String,
    },
    skills: {
      required: true,
      type: Array as PropType<string[]>,
    },
  },
});
