
import { PropType, defineComponent } from "vue";
import ProjectThumbnail from "./ProjectThumbnail.vue";
import { IProject } from "@/types";

export default defineComponent({
  name: "ProjectsGrid",
  components: { ProjectThumbnail },
  props: {
    projects: {
      required: true,
      type: Array as PropType<IProject[]>,
    },
  },
});
