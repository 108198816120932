
import { defineComponent, onMounted } from "vue";
import { Filters, PageHeader, ProjectsGrid } from "@/components";
import { useDB } from "@/composables";
import { setupIsotopeFilters } from "@/libs/isotope-custom";

export default defineComponent({
  name: "Projects",
  components: {
    Filters,
    PageHeader,
    ProjectsGrid,
  },
  setup() {
    const { projects, projectFilters } = useDB();
    onMounted(() => setupIsotopeFilters(["project-filters"]));
    return { projects, projectFilters };
  },
});
