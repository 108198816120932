
import { defineComponent } from "vue";
import ProjectsGrid from "./ProjectsGrid.vue";
import { useFeaturedProjects } from "@/composables";

export default defineComponent({
  name: "FeaturedProjects",
  components: { ProjectsGrid },
  setup() {
    const { projects } = useFeaturedProjects();
    return { projects };
  },
});
