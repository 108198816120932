import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "project-wrapper container py-5" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 col-lg-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProjectHeader = _resolveComponent("ProjectHeader")
  const _component_router_view = _resolveComponent("router-view")
  const _component_ProjectInfo = _resolveComponent("ProjectInfo")
  const _component_OtherProjectCaseStudies = _resolveComponent("OtherProjectCaseStudies")

  return (_ctx.project)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_ProjectHeader, { project: _ctx.project }, null, 8, ["project"]),
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, [
            _createVNode("section", _hoisted_4, [
              _createVNode(_component_router_view, {
                project: _ctx.project,
                testimonials: _ctx.testimonials
              }, null, 8, ["project", "testimonials"])
            ]),
            _createVNode(_component_ProjectInfo, {
              class: "col-12 col-lg-4 ps-lg-5",
              project: _ctx.project
            }, null, 8, ["project"])
          ]),
          _createVNode(_component_OtherProjectCaseStudies, { projects: _ctx.otherProjects }, null, 8, ["projects"])
        ])
      ]))
    : _createCommentVNode("", true)
}