
import { PropType, defineComponent } from "vue";
import { useDB } from "@/composables";
import { ITalk } from "@/types";

export default defineComponent({
  name: "Talk",
  props: {
    talk: {
      type: Object as PropType<ITalk>,
      required: true,
    },
  },
  setup() {
    const { flags } = useDB();
    return { flags };
  },
});
