
import { PropType, defineComponent } from "vue";
import {
  Navigation,
  Pagination,
  Slide,
  Carousel as Vue3Carousel,
} from "vue3-carousel";

export default defineComponent({
  name: "Carousel",
  components: {
    Vue3Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    itemsToShow: {
      type: Number,
      required: true,
    },
    paths: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
