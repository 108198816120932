<template>
  <section class="section-proof section bg-white py-5 companies">
    <div class="container">
      <h3 class="section-title font-weight-bold text-center mb-5">
        Great companies I've worked with
      </h3>
      <div class="logos row mb-5">
        <div class="logo px-3 col-6 col-md-3 col-lg-3 me-0 px-md-3 px-lg-3">
          <a class="logo-link" href="https://www.ardentcode.com/">
            <img
              class="grayscale img-fluid svg-ie-fix"
              src="images/logos/logo-ardent-code.png"
            />
          </a>
        </div>
        <div class="logo px-3 col-6 col-md-3 col-lg-3 me-0 px-md-3 px-lg-3">
          <a class="logo-link" href="https://www.wolterskluwer.com/">
            <img
              class="grayscale img-fluid svg-ie-fix"
              src="images/logos/logo-wolters-kluwer.svg"
            />
          </a>
        </div>
        <div class="logo px-3 col-6 col-md-3 col-lg-3 me-0 px-md-3 px-lg-3">
          <a class="logo-link" href="https://www.obi4wan.com/">
            <img
              class="grayscale img-fluid svg-ie-fix"
              src="images/logos/logo-obi4wan.png"
            />
          </a>
        </div>
        <div class="logo px-3 col-6 col-md-3 col-lg-3 me-0 px-md-3 px-lg-3">
          <a class="logo-link" href="https://www.engrave.website/">
            <img
              class="grayscale img-fluid svg-ie-fix"
              src="images/logos/logo-engrave.png"
            />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
