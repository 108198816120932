
import { PropType, defineComponent } from "vue";
import { PageHeader } from "@/components";
import { IProject } from "@/types";

export default defineComponent({
  name: "ProjectHeader",
  components: { PageHeader },
  props: {
    project: {
      type: Object as PropType<IProject>,
      required: true,
    },
  },
});
