import { allFilterTag } from "./types";
import { IDB } from "./types/IDB";

const db: IDB = {
  projects: [
    {
      name: "One",
      slug: "one",
      companyName: "Wolters Kluwer, via ArdentCode",
      thumbnail: "one/one.jpg",
      summaryTitle: "One",
      summary:
        "The ideal partner for professionals and companies who want to make a big difference",
      filterTags: ["team-leading", "automated-testing", "manual-testing"],
      badges: ["Team Leading", "Automated Testing", "Manual Testing"],
      technologies: ["javascript", "angular", "jira", "git", "github"],
      thumbnailLogo: "logo-wolters-kluwer.svg",
      featured: true,
      url: "https://www.wolterskluwer.com/it-it/solutions/one/onelavoro",
      type: "Web Application",
    },
    {
      name: "SpisTresci.pl",
      slug: "spistresci-pl",
      type: "Mobile Application",
      url: "https://spistresci.pl/",
      archivedUrl:
        "https://web.archive.org/web/20140924014455/http://spistresci.pl/",
      companyName: "SpisTresci.pl",
      thumbnail: "spistresci-pl/main.png",
      summaryTitle: "SpisTresci.pl",
      summary:
        "SpisTresci.pl was a price comparison website focused on ebooks, audiobooks, which was targeted towards the Polish market.",
      filterTags: ["Co-Founder", "Business Development", "HTML", "CSS"],
      badges: ["Co-Founder", "Business Development", "HTML", "CSS"],
      technologies: [
        "python",
        "django",
        "jquery",
        "solr",
        "sqlalchemy",
        "testrail",
      ],
      thumbnailLogo: "spistresci.png",
      featured: true,
      repositoryUrl: "https://github.com/spistresci/",
    },
    {
      name: "MediRegs",
      slug: "mediregs",
      companyName: "Wolters Kluwer, via ArdentCode",
      thumbnail: "mediregs/mediregs.jpg",
      summaryTitle: "MediRegs",
      summary:
        "MediRegs platform, used every day by thousands of healthcare professionals in USA, allows users to easily receive and manage primary regulatory and coding information.",
      filterTags: ["software-development", "Java Script", "HTML", "CSS"],
      badges: ["Software Development", "Java Script", "HTML", "CSS"],
      technologies: [
        "javascript",
        "angularjs",
        "bamboo",
        "jira",
        "git",
        "bitbucket",
        "protractor",
        "selenium",
        "cucumber",
        "browserstack",
        "x-ray",
        "html5",
        "css3",
      ],
      thumbnailLogo: "logo-wolters-kluwer.svg",
      featured: true,
      url: "http://www.wkmediregs.com/",
      type: "Web Application",
    },
    {
      name: "Wiadomości Chemiczne",
      slug: "wiadomosci-chemiczne",
      companyName: "Side Project",
      thumbnail: "one/one.jpg",
      summaryTitle: "Wiadomości Chemiczne",
      summary:
        "The ideal partner for professionals and companies who want to make a big difference",
      filterTags: ["software-development", "manual-testing"],
      badges: ["Software Development", "Manual Testing"],
      technologies: [
        "javascript",
        "html5",
        "css3",
        "bootstrap",
        "github",
        "git",
      ],
      thumbnailLogo: "logo-wolters-kluwer.svg",
      featured: false,
      url: "https://www.wolterskluwer.com/it-it/solutions/one/onelavoro",
      type: "Web Application",
    },
    {
      name: "OBI4wan",
      slug: "obi4wan",
      companyName: "OBI4wan, via ArdentCode",
      thumbnail: "obi4wan/obi4wan.jpg",
      summaryTitle: "Obi4wan",
      summary:
        "The ideal partner for professionals and companies who want to make a big difference",
      filterTags: ["software-development", "manual-testing"],
      badges: ["Software Development", "Manual Testing"],
      technologies: [
        "javascript",
        "html5",
        "css3",
        "bootstrap",
        "github",
        "git",
      ],
      thumbnailLogo: "logo-wolters-kluwer.svg",
      featured: false,
      url: "https://www.wolterskluwer.com/it-it/solutions/one/onelavoro",
      type: "Web Application",
    },
  ],
  projectFilters: [
    { name: "All", tag: allFilterTag },
    { name: "Automated Testing", tag: "automated-testing" },
    { name: "Manual Testing", tag: "manual-testing" },
    { name: "Software Development", tag: "software-development" },
    { name: "Team Leading", tag: "team-leading" },
  ],
  talks: [
    {
      source: "youtube",
      id: "fz1Oz47q5IU",
      type: "video",
      language: "English",
      title: "How to use and store your Bitcoins in a secure way",
      description: "",
      date: "Jan 4, 2018",
      filterTags: ["blockchain"],
    },
    {
      source: "youtube",
      id: "eJaTp3CJo0o",
      type: "video",
      language: "Polish",
      title: "Docker - Easy Containerization",
      description: "",
      date: "Jun 23, 2015",
      filterTags: ["docker"],
    },
    {
      source: "youtube",
      id: "_hQm3OV0Ii0",
      type: "video",
      language: "Polish",
      title: "Git workflow",
      description: "",
      date: "Oct 18, 2018",
      filterTags: ["git"],
    },
    {
      source: "youtube",
      conference: "Women Techmakers Warszawa #4 & DevGirls",
      where: "Warszawa, Campus Google",
      id: "vnB-rioTwjU",
      language: "Polish",
      type: "video",
      title: "Blockchain - how it works",
      description: "",
      date: "Jun 24, 2017",
      filterTags: ["blockchain"],
    },
    {
      source: "youtube",
      id: "RuKSncf7Wrw",
      type: "video",
      language: "English",
      title: "Lightning Network",
      description: "",
      date: "Apr 3, 2017",
      filterTags: ["blockchain"],
    },
    {
      source: "youtube",
      id: "WytjcbnTPZw",
      type: "video",
      language: "Polish",
      title: "Steem i Steemit - zdecentralizowane social media na blockchain",
      conference: "9. Crypto@Cracow meetup",
      description: "",
      date: "Feb 5, 2018",
      filterTags: ["blockchain"],
    },
    // {
    //   source: "youtube",
    //   id: "",
    //   type: "video",
    //   title: "",
    //   description: "",
    //   date: "",
    // },
  ],
  talkFilters: [
    { name: "All", tag: allFilterTag },
    { name: "Git", tag: "git" },
    { name: "Docker", tag: "docker" },
    { name: "Blockchain", tag: "blockchain" },
  ],
  languageFilters: [
    { name: "🌎", tag: allFilterTag },
    { name: "🇬🇧", tag: "English" },
    { name: "🇵🇱", tag: "Polish" },
  ],
  testimonials: [
    {
      author: "Piotr Dulak",
      title: "CEO, ArdentCode",
      type: "linkedin",
      pictureUrl:
        "https://media-exp1.licdn.com/dms/image/C4D03AQE1HPjVGIYXjQ/profile-displayphoto-shrink_200_200/0/1524589533073?e=1653523200&v=beta&t=0zAxQJ-B4ieTVMWe19HJ_oKh8tqY6D6WDAQqnJ_nykc",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu dolor felis. Aenean pretium consequat purus eget sodales. Aenean dictum pulvinar sem vel semper. Vivamus molestie euismod velit nec egestas. Sed eget diam vitae enim iaculis commodo et vel tellus. Morbi porttitor lorem ut tristique porttitor. Cras quam lorem, semper at lacus nec, condimentum condimentum lacus.",
      relevantForProjects: ["obi4wan", "mediregs", "one"],
      url: "https://www.linkedin.com/in/magdalena-szumna-95a31a79/",
    },
    {
      author: "Hanna Cuper",
      title: "Quality Assurance Automation, ArdentCode",
      type: "linkedin",
      pictureUrl:
        "https://media-exp1.licdn.com/dms/image/C4E03AQHWgyH4XRWXoQ/profile-displayphoto-shrink_800_800/0/1634212310232?e=1653523200&v=beta&t=TQRh2mYRMSlfBXv1UGaXVvIFbjC2PzcUN6cW3CSM3pQ",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu dolor felis. Aenean pretium consequat purus eget sodales. Aenean dictum pulvinar sem vel semper. Vivamus molestie euismod velit nec egestas. Sed eget diam vitae enim iaculis commodo et vel tellus. Morbi porttitor lorem ut tristique porttitor. Cras quam lorem, semper at lacus nec, condimentum condimentum lacus.",
      relevantForProjects: ["mediregs", "one"],
      url: "https://www.linkedin.com/in/magdalena-szumna-95a31a79/",
    },
    {
      author: "Łukasz Gronowski",
      title: "QA Automation Engineer, Sii Poland",
      type: "linkedin",
      pictureUrl:
        "https://media-exp1.licdn.com/dms/image/C5603AQG4EuUk13Vf0Q/profile-displayphoto-shrink_100_100/0/1516277136633?e=1626912000&v=beta&t=wFgJg724bKxY4QtqGwk4jLfKmJ_qa_lQkHDhLBcmoz8",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu dolor felis. Aenean pretium consequat purus eget sodales. Aenean dictum pulvinar sem vel semper. Vivamus molestie euismod velit nec egestas. Sed eget diam vitae enim iaculis commodo et vel tellus. Morbi porttitor lorem ut tristique porttitor. Cras quam lorem, semper at lacus nec, condimentum condimentum lacus.",
      relevantForProjects: ["mediregs", "one"],
      url: "https://www.linkedin.com/in/magdalena-szumna-95a31a79/",
    },
    {
      author: "Paliter",
      title: "Project Manager, Obi4wan",
      type: "linkedin",
      pictureUrl:
        "https://media-exp1.licdn.com/dms/image/C4E03AQFLUNlcrSJn2g/profile-displayphoto-shrink_200_200/0/1517625984734?e=1629331200&v=beta&t=guTPmpV1coi0KnO0y9570mHM8lC1O8qATSgLz8We1zo",
      body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque eu dolor felis. Aenean pretium consequat purus eget sodales. Aenean dictum pulvinar sem vel semper. Vivamus molestie euismod velit nec egestas. Sed eget diam vitae enim iaculis commodo et vel tellus. Morbi porttitor lorem ut tristique porttitor. Cras quam lorem, semper at lacus nec, condimentum condimentum lacus.",
      relevantForProjects: ["obi4wan"],
      url: "https://www.linkedin.com/in/magdalena-szumna-95a31a79/",
    },
  ],
  links: {
    linkedinProfile: "https://www.linkedin.com/in/krzysztofszumny/",
    twitterProfile: "https://twitter.com/noisypl",
    githubProfile: "https://github.com/noisy",
    stackOverflowProfile: "https://stackoverflow.com/users/338581/noisy",
  },
  contactEmail: "hello@krzysztofszumny.com",
  flags: {
    Polish: "🇵🇱",
    English: "🇬🇧",
  },
  blogPosts: [
    {
      title:
        "How I bankrupt my first startup by not understanding the definition of MVP - Minimum Viable Product",
      author: "Krzysztof Szumny",
      slug: "mvp",
      published: true,
      thumbnail:
        "https://steemitimages.com/640x0/https://ipfs.pics/ipfs/QmYq5xu4N98WUJMjwjaGwaCZpgPWzNyhsrAkLaGvLS3uC1",
      publishedAt: new Date("2016-08-22 20:51"),
      intro:
        "Blog post intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel sapien quis nulla dictum euismod. Vivamus sed mi vitae dui iaculis venenatis...",
      tagline: "About how to not make a very costly mistake...",
    },
    {
      title: "Example blog post about programming",
      author: "Krzysztof Szumny",
      slug: "example",
      published: true,
      thumbnail: "/images/blog/blog-post-thumb-2.jpg",
      publishedAt: new Date(),
      intro:
        "Blog post intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel sapien quis nulla dictum euismod. Vivamus sed mi vitae dui iaculis venenatis...",
      tagline: "Example of article about programming",
    },
    // {
    //   title: "title",
    //   author: "author",
    //   slug: "seerene",
    //   published: true,
    //   thumbnail: "/images/blog/blog-post-thumb-2.jpg",
    //   publishedAt: new Date(),
    //   intro:
    //     "Blog post intro goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel sapien quis nulla dictum euismod. Vivamus sed mi vitae dui iaculis venenatis...",
    // },
  ],
};

export default db;
