import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Bio = _resolveComponent("Bio")
  const _component_SkillsOverview = _resolveComponent("SkillsOverview")
  const _component_Companies = _resolveComponent("Companies")
  const _component_Testimonials = _resolveComponent("Testimonials")
  const _component_FeaturedProjects = _resolveComponent("FeaturedProjects")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Bio),
    _createVNode(_component_SkillsOverview),
    _createVNode(_component_Companies),
    _createVNode(_component_Testimonials, { testimonials: _ctx.testimonials }, null, 8, ["testimonials"]),
    _createVNode(_component_FeaturedProjects)
  ], 64))
}