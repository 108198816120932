import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "skills-block col-12 col-lg-4 mb-5 mb-3 mb-lg-0" }
const _hoisted_2 = { class: "skills-block-inner bg-white shadow-sm py-4 px-5 position-relative" }
const _hoisted_3 = { class: "skills-cat text-center mb-3 mt-5" }
const _hoisted_4 = { class: "\n          skills-icon-holder\n          position-absolute\n          d-inline-block\n          rounded-circle\n          text-center\n        " }
const _hoisted_5 = { class: "skills-list list-unstyled text-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("h4", _hoisted_3, _toDisplayString(_ctx.groupName), 1),
      _createVNode("div", _hoisted_4, [
        _createVNode("img", {
          class: "skills-icon",
          src: _ctx.icon
        }, null, 8, ["src"])
      ]),
      _createVNode("ul", _hoisted_5, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.skills, (skill) => {
          return (_openBlock(), _createBlock("li", {
            key: skill,
            class: "mb-2"
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: "check",
              class: "me-2 text-primary"
            }),
            _createTextVNode(_toDisplayString(skill), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}