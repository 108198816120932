
import { PropType, defineComponent } from "vue";
import { Quote } from "@/components";
import { IBlogPost } from "@/types";

export default defineComponent({
  name: "MVP",
  components: { Quote },
  props: {
    blogPost: {
      type: Object as PropType<IBlogPost>,
      required: true,
    },
  },
  setup(props) {
    return {
      img: (filename: string) =>
        `/images/blog/${props.blogPost.slug}/${filename}`,
    };
  },
});
