
import { PropType, defineComponent } from "vue";
import { IProject } from "@/types";

export default defineComponent({
  name: "ProjectInfo",
  props: {
    project: {
      required: true,
      type: Object as PropType<IProject>,
    },
  },
});
