
import { PropType, defineComponent } from "vue";
import {
  BlogPostThumbnail,
  Carousel,
  CoverImage,
  Quote,
  Testimonial,
} from "@/components";
import { useDB, useProject } from "@/composables";
import { IProject, ITestimonial } from "@/types";

export default defineComponent({
  name: "SpisTresci",
  components: {
    CoverImage,
    Testimonial,
    Carousel,
    Quote,
    BlogPostThumbnail,
  },
  props: {
    project: {
      type: Object as PropType<IProject>,
      required: true,
    },
    testimonials: {
      type: Array as PropType<ITestimonial[]>,
      required: true,
    },
  },
  setup(props) {
    const { blogPosts } = useDB();
    const { getImagesPaths } = useProject();
    return {
      imagesPaths: getImagesPaths(props.project, 4),
      mvpBlogPost: blogPosts.find((post) => post.slug == "mvp"),
      img: (filename: string) => `/images/projects/spistresci-pl/${filename}`,
    };
  },
});
