
import { defineComponent, onBeforeMount, onBeforeUpdate, ref } from "vue";
import { useRoute } from "vue-router";
import {
  OtherProjectCaseStudies,
  ProjectHeader,
  ProjectInfo,
} from "@/components";
import { useDB } from "@/composables";

export default defineComponent({
  name: "Project",
  components: {
    ProjectHeader,
    ProjectInfo,
    OtherProjectCaseStudies,
  },
  setup() {
    const { projects, testimonials } = useDB();
    let project = ref();
    let projectTestimonials = ref();

    const loadProjectData = () => {
      const route = useRoute();
      project.value = projects.find((p) => `project-${p.slug}` == route.name);

      projectTestimonials.value = testimonials.filter((p) =>
        p.relevantForProjects.includes(project.value.slug)
      );
    };
    onBeforeMount(loadProjectData);
    onBeforeUpdate(loadProjectData);

    return {
      project,
      otherProjects: projects,
      testimonials: projectTestimonials,
    };
  },
});
