import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("ul", {
      id: _ctx.name,
      class: "filters mb-5 mx-auto ps-0"
    }, [
      _createVNode("li", {
        class: "type active",
        "data-filter": _ctx.allFilterTag
      }, _toDisplayString(_ctx.allFilterName), 9, ["data-filter"]),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.nonAllFilters, ({ tag, name: filterName }) => {
        return (_openBlock(), _createBlock("li", {
          key: tag,
          class: "type",
          "data-filter": tag
        }, _toDisplayString(filterName), 9, ["data-filter"]))
      }), 128))
    ], 8, ["id"])
  ]))
}