import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "footer text-light text-center py-2" }
const _hoisted_2 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HireMeFooter = _resolveComponent("HireMeFooter")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_HireMeFooter),
    _createVNode("footer", _hoisted_1, [
      _createVNode("small", _hoisted_2, "Built with Vue.js 3 & Bootstrap 5. © " + _toDisplayString(_ctx.currentYear) + " Magdalena Szumna. All rights reserverd.", 1)
    ])
  ], 64))
}