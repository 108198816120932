
import { defineComponent } from "vue";
import { useDB } from "@/composables";

export default defineComponent({
  name: "BlogPostHeader",
  setup() {
    const { links } = useDB();
    return { links };
  },
});
