import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "project-cards row mb-5 isotope" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_project_thumbnail = _resolveComponent("project-thumbnail")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.projects, (project) => {
        return (_openBlock(), _createBlock(_component_project_thumbnail, {
          key: project.slug,
          class: "isotope-item",
          project: project
        }, null, 8, ["project"]))
      }), 128))
    ])
  ]))
}