
import { defineComponent, onMounted } from "vue";
import { Filters, PageHeader, Talk } from "@/components";
import { useDB } from "@/composables";
import { setupIsotopeFilters } from "@/libs/isotope-custom";

export default defineComponent({
  name: "Talks",
  components: {
    PageHeader,
    Talk,
    Filters,
  },
  setup() {
    const { talks, talkFilters, languageFilters } = useDB();
    onMounted(() => setupIsotopeFilters(["talk-filters", "language-filters"]));
    return { talks, talkFilters, languageFilters };
  },
});
