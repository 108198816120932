import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "single-post-wrapper single-col-max-width py-5 px-4 mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlogPostHeader = _resolveComponent("BlogPostHeader")
  const _component_CoverImage = _resolveComponent("CoverImage")
  const _component_router_view = _resolveComponent("router-view")

  return (_ctx.blogPost)
    ? (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode(_component_BlogPostHeader, { "blog-post": _ctx.blogPost }, null, 8, ["blog-post"]),
        _createVNode("section", _hoisted_2, [
          _createVNode(_component_CoverImage, {
            url: _ctx.blogPost.thumbnail
          }, null, 8, ["url"]),
          _createVNode(_component_router_view, { "blog-post": _ctx.blogPost }, null, 8, ["blog-post"])
        ])
      ]))
    : _createCommentVNode("", true)
}