
import { PropType, defineComponent } from "vue";
import { IBlogPost } from "@/types";

export default defineComponent({
  name: "BlogPostThumbnail",
  props: {
    blogPost: {
      type: Object as PropType<IBlogPost>,
      required: true,
    },
  },
  setup() {
    return {};
  },
});
