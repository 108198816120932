
import { defineComponent, onBeforeMount, onBeforeUpdate, ref } from "vue";
import { useRoute } from "vue-router";
import { BlogPostHeader, CoverImage } from "@/components";
import { useDB } from "@/composables";

export default defineComponent({
  name: "BlogPost",
  components: { BlogPostHeader, CoverImage },
  setup() {
    const { blogPosts } = useDB();
    let blogPost = ref();

    const loadBlogPostData = () => {
      const route = useRoute();
      blogPost.value = blogPosts.find((p) => `blog-${p.slug}` == route.name);
    };
    onBeforeMount(loadBlogPostData);
    onBeforeUpdate(loadBlogPostData);

    return { blogPost };
  },
});
