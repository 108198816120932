import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "col-12 col-auto talk-media-holder ratio ratio-16x9 me-md-3" }
const _hoisted_2 = { class: "col p-4" }
const _hoisted_3 = { class: "talk-meta list-inline mb-2" }
const _hoisted_4 = { class: "list-inline-item me-3" }
const _hoisted_5 = {
  class: "list-inline-item me-3",
  style: {"text-transform":"capitalize"}
}
const _hoisted_6 = { class: "list-inline-item me-3" }
const _hoisted_7 = {
  class: "flag me-2",
  style: {"margin-top":"-2px"}
}
const _hoisted_8 = { class: "lang" }
const _hoisted_9 = { class: "talk-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")

  return (_openBlock(), _createBlock("div", {
    class: ["row mb-5 flex-column flex-lg-row bg-white shadow-sm", [..._ctx.talk.filterTags, _ctx.talk.language]]
  }, [
    _createVNode("div", _hoisted_1, [
      (_ctx.talk.source == 'youtube')
        ? (_openBlock(), _createBlock("iframe", {
            key: 0,
            width: "560",
            height: "315",
            src: 'https://www.youtube.com/embed/' + _ctx.talk.id,
            title: "YouTube video player",
            frameborder: "0",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            allowfullscreen: ""
          }, "\n      ", 8, ["src"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("h4", null, _toDisplayString(_ctx.talk.title), 1),
      _createVNode("ul", _hoisted_3, [
        _createVNode("li", _hoisted_4, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['far', 'clock'],
            class: "me-2"
          }),
          _createTextVNode(_toDisplayString(_ctx.talk.date), 1)
        ]),
        _createVNode("li", _hoisted_5, [
          (_ctx.talk.type == 'podcast')
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                class: "me-2",
                icon: "headphones"
              }))
            : (_ctx.talk.type == 'video')
              ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                  key: 1,
                  class: "me-2",
                  icon: "video"
                }))
              : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.talk.type), 1)
        ]),
        _createVNode("li", _hoisted_6, [
          _createVNode("span", _hoisted_7, _toDisplayString(_ctx.flags[_ctx.talk.language]), 1),
          _createVNode("span", _hoisted_8, _toDisplayString(_ctx.talk.language), 1)
        ])
      ]),
      _createVNode("div", _hoisted_9, _toDisplayString(_ctx.talk.description ||
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus penatibus et magnis dis parturient montes, nascetur ridiculus mus.."), 1)
    ])
  ], 2))
}