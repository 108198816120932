
import { defineComponent } from "vue";
import HireMeFooter from "./HireMeFooter.vue";

export default defineComponent({
  name: "Footer",
  components: { HireMeFooter },
  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
});
