
import { defineComponent } from "vue";
import { useDB } from "@/composables/useDB";

export default defineComponent({
  name: "HireMeFooter",
  setup() {
    const { contactEmail } = useDB();
    return { contactEmail };
  },
});
