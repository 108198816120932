
import { defineComponent, watch } from "vue";
import { useRouter } from "vue-router";
import { useNavigation } from "@/composables";

export default defineComponent({
  name: "Navigation",
  setup() {
    const router = useRouter();
    const { updateSlideLine } = useNavigation();
    watch(router.currentRoute, updateSlideLine);
    return {};
  },
  data: () => ({
    menuCollapsed: true,
  }),
  methods: {
    toggleMenu() {
      this.menuCollapsed = !this.menuCollapsed;
    },
    closeMenu() {
      this.menuCollapsed = true;
    },
  },
});
